import React from "react"
import { Link } from "gatsby"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"
// import { ModeToggle } from "../components/toggle"

export default () => {
  // class ModeToggle extends React.Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {isToggleOn: true};
  
  //     // This binding is necessary to make `this` work in the callback
  //     this.handleClick = this.handleClick.bind(this);
  //   }
  
  //   handleClick() {
  //     this.setState(state => ({
  //       isToggleOn: !state.isToggleOn
  //     }));
  //   }
  
  //   render() {
  //     return (
  //       <button  className="toggle" onClick={this.handleClick}>
  //         {this.state.isToggleOn 
  //         ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />}
  //       </button>
  //     );
  //   }
  // }
  return(
    <header className="header">
        <div className="container">
          <div className="site">
            <Link to={`/`}>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 180 40" height="40" width="180" fill="#1e3040" >
                    <defs></defs>
                    <desc>MITTY.BLOG</desc>
                    <path 
                      d="M5.9 30.118V9.355h6.274l3.767 14.163 3.725-14.163h6.288v20.763H22.07V13.774l-4.12 16.344h-4.036l-4.11-16.344v16.344zm23.77 0V9.355h4.192v20.763zm12.484 0v-17.25h-6.16V9.355h16.5v3.512h-6.147v17.25zm17.372 0v-17.25h-6.16V9.355h16.5v3.512H63.72v17.25zm18.15 0v-8.74L70.07 9.355h4.915l4.886 8.215 4.787-8.215h4.83l-7.634 12.053v8.7zm10.304 0v-3.98h3.98v3.98zm7.726-20.763h8.3q2.464 0 3.668.212 1.218.198 2.167.85.963.65 1.6 1.742.637 1.076.637 2.422 0 1.46-.793 2.677-.78 1.218-2.124 1.827 1.898.552 2.918 1.884 1.02 1.33 1.02 3.13 0 1.416-.666 2.762-.65 1.33-1.8 2.14-1.133.793-2.804.977-1.048.113-5.056.142h-7.067zM99.9 12.8v4.8h2.748q2.45 0 3.045-.07 1.076-.127 1.685-.736.623-.623.623-1.63 0-.963-.538-1.558-.524-.6-1.572-.736-.623-.07-3.583-.07zm0 8.257v5.552h3.88q2.266 0 2.875-.127.935-.17 1.515-.82.595-.666.595-1.77 0-.935-.453-1.586-.453-.65-1.317-.95-.85-.297-3.7-.297zm16.506 9.06V9.525h4.192V26.62h10.424v3.498zm16.408-10.254q0-3.173.95-5.325.708-1.586 1.926-2.847 1.232-1.26 2.7-1.87 1.94-.82 4.476-.82 4.6 0 7.336 2.847 2.762 2.847 2.762 7.917 0 5.028-2.733 7.875-2.733 2.833-7.308 2.833-4.63 0-7.365-2.818-2.733-2.833-2.733-7.8zm4.32-.142q0 3.527 1.63 5.354 1.63 1.813 4.136 1.813 2.507 0 4.107-1.8 1.615-1.813 1.615-5.424 0-3.57-1.572-5.325-1.558-1.756-4.15-1.756-2.592 0-4.178 1.785-1.586 1.77-1.586 5.354zm28.366 2.762v-3.498h9.036v8.27q-1.317 1.275-3.824 2.252-2.493.963-5.056.963-3.257 0-5.68-1.36-2.422-1.374-3.64-3.9-1.218-2.55-1.218-5.538 0-3.243 1.36-5.764 1.36-2.52 3.98-3.866Q162.465 9 165.44 9q3.867 0 6.033 1.63 2.18 1.615 2.804 4.476l-4.164.78q-.44-1.53-1.657-2.408-1.204-.892-3.017-.892-2.748 0-4.376 1.742-1.615 1.742-1.615 5.17 0 3.697 1.643 5.552 1.643 1.84 4.306 1.84 1.317 0 2.634-.5 1.33-.524 2.28-1.26v-2.634z"
                    />
                </svg>
              </h1>
            </Link>
          </div>
          
          <nav className="nav">
            <ul>
              <li><Link to={`/`}>TOP</Link></li>
              <li><Link to={`/blog/`}>BLOG</Link></li>
              <li><Link to={`/categorylist/`}>CATEGORY</Link></li>
            </ul>
          </nav>
        </div>
      </header>
  )
}